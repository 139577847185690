$('body').on('click', '[navigation-target]', function(e) {
  e.preventDefault();
  let section = $(this).attr('navigation-target');
  $(`[navigation-section=${section}]`).fadeIn();
});

$('body').on('click', '[navigation-target="navigation-home"]', function() {
  $('[navigation-section="navigation-home"]').show();
  $('[navigation-section="navigation-women"]').hide();
  $('[navigation-section="navigation-men"]').hide();
  $('[navigation-section="navigation-juniors"]').hide();
  $('[navigation-section="navigation-creative"]').hide();
  $('[navigation-section="navigation-families-couples"]').hide();
});
$('body').on('click', '[navigation-target="navigation-women"]', function() {
    console.log('test');
  $('[navigation-section="navigation-home"]').hide();
  $('[navigation-section="navigation-women"]').show();
  $('[navigation-section="navigation-men"]').hide();
  $('[navigation-section="navigation-juniors"]').hide();
  $('[navigation-section="navigation-creative"]').hide();
  $('[navigation-section="navigation-families-couples"]').hide();
});
$('body').on('click', '[navigation-target="navigation-men"]', function() {
    console.log('test');
  $('[navigation-section="navigation-home"]').hide();
  $('[navigation-section="navigation-women"]').hide();
  $('[navigation-section="navigation-men"]').show();
  $('[navigation-section="navigation-juniors"]').hide();
  $('[navigation-section="navigation-creative"]').hide();
  $('[navigation-section="navigation-families-couples"]').hide();
});
$('body').on('click', '[navigation-target="navigation-juniors"]', function() {
    console.log('test');
  $('[navigation-section="navigation-home"]').hide();
  $('[navigation-section="navigation-women"]').hide();
  $('[navigation-section="navigation-men"]').hide();
  $('[navigation-section="navigation-juniors"]').show();
  $('[navigation-section="navigation-creative"]').hide();
  $('[navigation-section="navigation-families-couples"]').hide();
});

$('body').on('click', '[navigation-target="navigation-creative"]', function() {
  $('[navigation-section="navigation-home"]').hide();
  $('[navigation-section="navigation-women"]').hide();
  $('[navigation-section="navigation-men"]').hide();
  $('[navigation-section="navigation-juniors"]').hide();
  $('[navigation-section="navigation-creative"]').show();
  $('[navigation-section="navigation-families-couples"]').hide();
});
$('body').on('click', '[navigation-target="navigation-families-couples"]', function() {
  $('[navigation-section="navigation-home"]').hide();
  $('[navigation-section="navigation-women"]').hide();
  $('[navigation-section="navigation-men"]').hide();
  $('[navigation-section="navigation-juniors"]').hide();
  $('[navigation-section="navigation-creative"]').hide();
  $('[navigation-section="navigation-families-couples"]').show();
});

$('#menu-trigger').on('click', function(){
  console.log('test');
  $('.burger-menu-container').toggleClass('burger-menu-container-active');
  $('body').toggleClass('body-fixed');
  $('.col-women').slideDown(100);
  $('.col-men').slideDown(400);
  $('.col-juniors').slideDown(650);
  $('.col-creative').slideDown(900);
  $('.col-families-couples').slideDown(1150);
});
$('#menu-trigger-close').on('click', function(){
  console.log('test');
  setTimeout(function() {
       $('.burger-menu-container').removeClass('burger-menu-container-active')
   }, 400);
   $('body').toggleClass('body-fixed');
  $('.col-women').slideUp();
  $('.col-men').slideUp();
  $('.col-juniors').slideUp();
  $('.col-creative').slideUp();
  $('.col-families-couples').slideUp();
});
