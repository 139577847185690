/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./general');
require('./libraries/bootstrap/bootstrap.bundle.min.js');
require('./libraries/uikit/uikit.js');
require('./components/navigation/burger-menu.js');
require('./components/apply-journey/apply-journey.js');
require('./components/swiper/swiper.js');

require('print-this');
require('./components/general/general.js');