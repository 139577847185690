// Show password
function myFunction() {
  var x = document.getElementById("myInput");
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
}

// Show File Input Name on Button CV Upload
$('#file-upload').change(function() {
  var i = $(this).prev('.file-select-name').clone();
  var file = $('#file-upload')[0].files[0].name;
  $(this).prev('.file-select-name').text(file);
});

// Show File Input Name on Button video Upload
$('#file-upload-video').change(function() {
  var i = $(this).prev('.file-select-name-video').clone();
  var file = $('#file-upload-video')[0].files[0].name;
  $(this).prev('.file-select-name-video').text(file);
});

// Splashpage
// $( document ).ready(function() {
//   $('#splashModal').fadeIn('fast').delay(2000).fadeOut('slow') ;
//   $('.logo-fade').delay(250).fadeIn('slow');
// });

// $( document ).ready(function() {
// if (localStorage.getItem("pageloadcount")) { 
//   $("#splashModal").hide();
// } 
//   localStorage.setItem("pageloadcount", "1");
// });


// // Cookies Policy
// let cookie = Cookies.get('cookie-bar');
// if(cookie!=='true'){
//     var myModal = new bootstrap.Modal(document.getElementById("cookieModal"), {});
//     myModal.show();
// }

// $('#cookieAccept').on('click', function (e) {
//   Cookies.set('cookie-bar', 'true', { expires: 14 });
// });


// function setCookie(cname, cvalue, exdays) {
//   var d = new Date();
//   d.setTime(d.getTime() + (exdays*24*60*60*1000));
//   var expires = "expires="+ d.toUTCString();
//   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
// }

$('body').on('click', '[applicant-print]', function() {
  var contents = '';

  $('[applicant-print-area]').each(function() {
    contents += `<hr>${$(this).html()}`;
  });

  $(contents).printThis();
});


// Scroll to top
var mybutton = document.getElementById("myBtn");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}