$(`#apply-journey-women`).on(`click`, function() {
	 $(`#apply-journey-women`).removeClass(`apply-image-button-inactive`).addClass(`apply-image-button-active`);
	 $(`#apply-journey-men, #apply-journey-juniors, #apply-journey-creative, #apply-journey-families-couples`).removeClass(`apply-image-button-active`).addClass(`apply-image-button-inactive`);
});

$(`#apply-journey-men`).on(`click`, function() {
	 $(`#apply-journey-men`).removeClass(`apply-image-button-inactive`).addClass(`apply-image-button-active`);
	 $(`#apply-journey-women, #apply-journey-juniors, #apply-journey-creative, #apply-journey-families-couples`).removeClass(`apply-image-button-active`).addClass(`apply-image-button-inactive`);
});

$(`#apply-journey-juniors`).on(`click`, function() {
	 $(`#apply-journey-juniors`).removeClass(`apply-image-button-inactive`).addClass(`apply-image-button-active`);
	 $(`#apply-journey-men, #apply-journey-women, #apply-journey-creative, #apply-journey-families-couples`).removeClass(`apply-image-button-active`).addClass(`apply-image-button-inactive`);
});

$(`#apply-journey-families-couples`).on(`click`, function() {
	 $(`#apply-journey-families-couples`).removeClass(`apply-image-button-inactive`).addClass(`apply-image-button-active`);
	 $(`#apply-journey-men, #apply-journey-juniors, #apply-journey-creative, #apply-journey-women`).removeClass(`apply-image-button-active`).addClass(`apply-image-button-inactive`);
});

$(`#apply-journey-creative`).on(`click`, function() {
	 $(`#apply-journey-creative`).removeClass(`apply-image-button-inactive`).addClass(`apply-image-button-active`);
	 $(`#apply-journey-men, #apply-journey-juniors, #apply-journey-women, #apply-journey-families-couples`).removeClass(`apply-image-button-active`).addClass(`apply-image-button-inactive`);
});

$(`body`).on(`click`, `[apply-division-target]`, function() {
	let target = $(this).attr(`apply-division-target`);
	$(`[apply-division]`).hide();
	$(`[apply-division=${target}]`).fadeIn();
});

$('body').on('click', '[apply-submit]', function() {
	$(this).prop('disabled', true);
	
	let applyInput = $(this).attr('apply-submit');
	let division = $(this).attr('apply-submit-division');

	if(division == 5) {
		submitFamilies();
	} else {
		submitDivision(applyInput);
	}
});

$('body').on('click', '[apply-confirm]', function() {
	var target = $(this).attr('apply-confirm');
	$(this).hide();
	$(`[apply-submit=${target}]`).fadeIn();
});

function submitFamilies()
{
	let applyInputs = [];
	let errors = false;
	let formData = new FormData();

	$('[family-model-target]').each(function() {
		applyInputs.push($(this).attr('family-model-target'));
	});

	$.each(applyInputs, function(key, applyInput) {

		$(`[apply-input=${applyInput}]`).each(function() {
			var name = `applicants[${key}][${$(this).attr('name')}]`;
			var type = $(this).attr('type');
			var required = $(this).attr('required');
			var value = $(this).val();

			console.log(`Input Name: ${name} with type of ${type}`);

			if(type == 'checkbox') {
				if($(this).is(':checked')) {
					console.log('checked');
					value = 1;
				} else {
					console.log('not checked');
					value = 0;
				}
			}

			if(! value && required) {
				errors = true;
				$(this).css('border', '1px solid red');
			} else if(value) {
				$(this).removeAttr('style');

				if(type == 'file') {
					formData.append(name, $(this)[0].files[0]);
				} else {
					formData.append(name, value);
				}
			}
		});
	});

	if(errors) {
		$('[apply-submit]').prop('disabled', false);
		$(`[apply-errors]`).html(`<div class="alert alert-danger">Please make sure you have entered all required information above.</div>`);
	} else {
		$.ajax({
	        headers: {
	            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	        },
		    url: '/apply/family',
		    type: 'POST',
		    data: formData,
		    mimeType: 'multipart/form-data',
		    async: false,
		    contentType: false,
		    cache: false,
		    processData: false,
		    success: function(response){
				window.location.href = '/apply/thank-you';
		    }
		});
	}
}

function submitDivision(applyInput)
{
	let errors = false;
	let formData = new FormData();

	$(`[apply-input=${applyInput}]`).each(function() {
		var name = $(this).attr('name');
		var type = $(this).attr('type');
		var required = $(this).attr('required');
		var value = $(this).val();

		if(type == 'checkbox') {
			if($(this).is(':checked')) {
				console.log('checked');
				value = 1;
			} else {
				console.log('not checked');
				value = 0;
			}
		}

		if(! value && required) {
			errors = true;
			$(this).css('border', '1px solid red');
		} else if(value) {
			$(this).removeAttr('style');

			if(type == 'file') {
				console.log(`File Value Is ${value}`);
				formData.append(name, $(this)[0].files[0]);
			} else {
				formData.append(name, value);
			}
		}
	});

	if(errors) {
		$('[apply-submit]').prop('disabled', false);
		$(`[apply-errors]`).html(`<div class="alert alert-danger">Please make sure you have entered all required information above.</div>`);
	} else {
		$.ajax({
	        headers: {
	            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	        },
		    url: '/apply',
		    type: 'POST',
		    data: formData,
		    mimeType: 'multipart/form-data',
		    async: false,
		    contentType: false,
		    cache: false,
		    processData: false,
		    success: function(response){
				window.location.href = '/apply/thank-you';
		    }
		});
	}
	
}