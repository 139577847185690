var swiper = new Swiper('.homepage-swiper', {
  // Optional parameters
   loop: true,

  // If we need pagination
  pagination: {
    el: '.hero-slider-fluid-1-pagination',
    clickable: true,
  },

  // Navigation arrows
    navigation: {
        nextEl: '.hero-slider-fluid-1-button-next',
        prevEl: '.hero-slider-fluid-1-button-prev',
    },
});


var swiper = new Swiper('.cta-swiper', {
  slidesPerView: 1,
  spaceBetween: 10,
  loop: true,
  pagination: {
    el: '.cta-slider-fluid-1-pagination',
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.cta-slider-fluid-1-button-next',
    prevEl: '.cta-slider-fluid-1-button-prev',
  },
  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 320px
    576: {
      slidesPerView: 1,
      spaceBetween: 20
    },
    // when window width is >= 480px
    992: {
      slidesPerView: 2,
      spaceBetween: 30
    },
    // when window width is >= 640px
    1140: {
      slidesPerView: 3,
      spaceBetween: 40
    }
  }
});